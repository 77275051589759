@import "../../../app/styles/colors";

.auth-background {
  position:fixed;
  bottom: 1px;
  display: flex;
  width: 100%;
  height: 100%;
  
}
.auth-background > img {
  width: 100%;
}

.mainContent {
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-30%, -30%);
  z-index: 1;
  background: hsl(0, 0, 100);
  border-radius: 36px;
  box-shadow: 0 3px 25px hsla(0, 0, 0, 0.2);
  overflow: hidden;
  display:flex;
  border: 8px solid;
  border-image-slice: 0;
  opacity: 0.9;
  border-image-source: linear-gradient(to right, #FF5733, #FF5733);


  .signup-connect,
  .signup-classic
  {
    width: 50%;
    padding: 2px 2px;
  }

  .signup-connect {
    background: #FFF; //linear-gradient(134deg, hsl(44, 96%, 65%), hsl(34, 95%, 45%));
    color: hsl(0, 0, 100);
  }

  .signup-classic {

    h1 {
      font-size: 20px;
      margin-top: 5px;
      margin-bottom: 10px;
      text-shadow: 0 2px 3px hsla(0, 0, 0, 0.1);
    }

    h2 {
      font-size: 14px;
      font-weight: normal;
      margin-top: 23px;
      margin-bottom: 43px;
      text-shadow: 0 2px 3px hsla(0, 0, 0, 0.1);
    }

    h3 {
      font-size: 10px;
      font-weight: normal;
      margin-top: 15px;
      margin-bottom: 43px;
      text-shadow: 0 2px 3px hsla(0, 0, 0, 0.1);
    }
    fieldset::after {
      content: '\f007';
      font-family: FontAwesome;
      position: absolute;
      right: 15px;
      top: 17px;
      z-index: 2;
      width: 20px;
      color: hsla(0, 0, 0, .2);
      text-align: center;
    }

    fieldset.email::after {
      content: '\f0e0';
    }

    fieldset.password::after {
      content: '\f023';
    }

  }
}
@media(min-width:856px) {
  .mainContent {
    
    width: 45%;
   
    .role-type {
      margin-right: 5%;
      margin-left: 5%;
      text-align: center;

      .logo {
        width: 100px;
        height: 100px;
        
        
      }
    }
  }
}
@media(max-width:856px) {
  .mainContent {
      width: 90%;

    .role-type {
      display: block;
      text-align: center;
      .logo {
        width: 150px;
        height: 150px;
      }
    }
    .signup-classic{
      width: 100%;
    }

    .signup-connect{
      display: none;
    }
  }
}

@media(max-width:452px) {
  .mainContent {
    width: 90%;

    .role-type {
      display: block;
      text-align: center;
      .logo {
        width: 150px;
        height: 150px;
      }
    }
    .signup-classic{
      width: 100%;
    }
    .signup-connect{
        display: none;
      }

      p {
        font-size: 10px;
      }
    }
  }

