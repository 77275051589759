@import '../../../../app/styles/style';

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.btn-uploader{
  width: 128px;
  height: 128px;
  border-style: dotted;
  text-align: center;
  vertical-align: middle;
}
