$foreground: #43494E;
$red: #E54256;
$white: #FFFFFF;

// Wp-Soft Colors
$woodland: #465023;
$eagle: #B6BA9B;
$foggyGray: #CDD0BD;
$greenWhite: #EBECE3;
$colonialWhite: #FDF5D7;
$webOrange: #FFA500;

$iconColor: #80855C;
$darkIconColor: #6F744B;
$sidebarIconColor: #555A34;

$inputPlaceholderColor: #585C41;
$checkboxTitleColor: #3B3E2A;
$separatorColor: #9A9E7E;
$listTitleColor: #3E2A2A;

$formLabelColor: #3E412F;
