@import "../../common/styles/Auth";

/*--------------------
Signup
--------------------*/
.logo-top {
  width:10px;
  height: 10px;
}

.logo-restpass {
  width:200px;
  height:200px;
}
.logo-selectAccount {
  width:100px;
  height:100px;
}


.dropdownlist{ 
 
  width: 285px;
  height: 40px;
  margin-top: 20px;
  border-color: purple;
  cursor: pointer;
  border-radius: 20px;

}

.role-type
{
  width: 100%;
  padding: 20px 50px;
  display:flex;
  align-items:center;
  font-size: 14px;

  a {
    margin-right: 10%;
  }
}

.login-section {
  width: 30%;
  min-width: 300px;
  height: 400px;
  position:relative;
  
  
  margin-top: 12%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 10%;
  
  z-index: 1;
 
  opacity: .90;
  border-radius: 20px;
 /* box-shadow: 0 10px 25px rgba(214, 149, 8, 0.2);*/
  overflow: hidden;
  /* display: flex; */
  border: 3px solid;
  float: right;
  border-radius: 50px 20px;
  
  border-image-slice: 0;
  border-image-source: linear-gradient(to right, #FF5733, #FF5733);
  padding: 1%;
}

.login-Radi {
  
  
  margin-top: -2%;
  
}
.login-forgetpass {
  
  
  margin-top: -10px;
  margin-right: 30px;
  
}


.login-foter {
  width: 100%;
  
  
  position: absolute;
  
  bottom: 0;
 
text-align: right;
  
background:  #FDB750;
 
 
  opacity: 0.90;
  
  color: black;
  
  /* display: flex; */
  border: 3px solid;
  
 


  border-image-source: linear-gradient(to right, #FF5733, #FF5733);
  
}
.login-foter_text {
  width: auto;

  position:absolute;
 
  bottom: 0;
 
  text-align: center;
  color: black;
 
  background:  #FDB750;
 
  /* display: flex; */


  
}


.button-section {
  
 
  
  background:  #24a0ed;
  
  border-radius: 10px;
  width: auto;
  opacity: 1;
  
  
 
  
}


.dashbord-section {
  width: 20%;
  position:relative;  
  z-index: 2;
  background: rgb(255, 203, 135);
  opacity: .90;
  border-radius: 1px;
  box-shadow: 0 10px 25px rgba(214, 149, 8, 0.2);
 
   text-align: center;

   /* display:flow-root; 
     overflow: hidden;

      border-image-slice: 0;
  border-image-source: linear-gradient(to right, #FF5733, #FF5733);
  padding: 1%;

  /*border: 3px solid;*/
 font-weight: bold;
 
  border-radius: 0px 50px;
  margin: 30%;
 
}
.dashbord-customer {
  
  width: 10%;
  position:relative;  
  z-index: 2;
  background: rgb(228, 228, 228);
  opacity: .90;
  border-radius: 1px;
  font-weight: bold;
   text-align: center;

  border-radius: 5px 50px;
  border-image-slice: 0;
  border-image-source: linear-gradient(to right, #FF5733, #FF5733);
  padding: 1%;
}

.dashbord-cart{

 
 background: #fffbed;
  
    //background-image:  "url(" + "images/webheader.png" + ")" ;
   font-size:large;
   text-align: center;
align-items: center;
border-radius: 20px 20px;
flex: 1;
width: 80%;
height: auto;

//display: flex;
box-shadow: 10px 10px 5px #ececec;

 

}
.mat-card {
  max-width:fit-content;
  
  font-weight: bold;
 ;
 font-size: large;
  text-align:justify;
}
.row-card {
  margin: 5px;
  margin-inline-start: 20px;
  margin-inline-end: -20px;
 
 

}
.chart_tital {
  
  text-align: center;
 
 

}



.dashbord-titl {
  width: auto;
  position:relative;  
  z-index: 2;
  background: #fffbed;
  opacity: .9;
  border-radius: 1px;
  
   text-align: center;
   
   /* display:flow-root; 
     overflow: hidden;
  /*border: 3px solid;*/

  border-radius: 20px 20px;
  border-image-slice: 0;
  border-image-source: linear-gradient(to right, #FF5733, #FF5733);
  padding: 1%;
}
.dashbord-total {
  
  background: rgb(238, 238, 238);
}
.dashbord-table{

  width: auto;
  position:relative;  
  z-index: 2;
 

   text-align:center;
  
  border-radius: 20px 20px;
  border-image-slice: 0;
  
  padding: 1%;

}

.charts{

  display: flex;
  margin: 10px;
 
  align-items: center;
 // box-shadow: 0px 10px 5px #fff5dd;
  
}
.chart_line{
 
  border-radius: 20px 20px;
   flex: 1;
   //display: flex;
   box-shadow: 10px 10px 5px #ececec;
 
}

.chart_bar{
  border-radius: 20px 20px;
  box-shadow: 10px 10px 5px #ececec;
  flex: 1;
  margin-right: 10px;
 
}
.chart_pie{
  border-radius: 25px 25px;

  box-shadow: 10px 10px 5px #ececec;
  flex: 1;
  margin-inline-start: 10px;
 
}
.piechartsDiv{

  display: flex;
  margin-inline-start: -10px;
 
  align-items: center;
 // box-shadow: 0px 10px 5px #fff5dd;
  
}


.tabl_line{
 
  border-radius: 20px 20px;
   flex: 1;
   margin: 5px;
   
   line-height: 0px;
 //  box-shadow: 10px 10px 5px #a2a2a2;
 
}

.textColer1{
 
color: #24a0ed;
font-weight: bold;
font-size: 20;
margin-inline-start: 50px;
}
.textColer2{
   color: #05ba77;
  font-weight: bold;
  font-size: 20;
  margin-inline-start: 30px;
  }



.logo-section {
  position: fixed;
  right: 10%;
}
.negative {
  color: red;
  }



  

@media(max-width:856px) {
  .login-section {
    width: 95%;
    position: fixed;
    left: 1%;
    top: 1%;
    padding: 1%;
    text-align: center;
  }
  .logo-section {
    display: none;
  }
}

@media(max-width:452px) {
  .login-section{
    left: 0;
    top: 0;
    width: 100%;

  }
  .logo-section {
    display: none;
    
  .fontclass {
    font-size: 14px;
    background-color: #FF5733;

  }
  
}

    
 
 
}

