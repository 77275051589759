@import "../../../app/styles/colors";

.ant-layout-sider {
  background:  #fccb81;
  
  .ant-layout-sider-children {
    background:  #fccb81;
 
    .ant-menu-dark{
      background: #fccb81;
      
      opacity: 1;
      color: black;

     

      .ant-menu-item {
        color: rgb(0, 0, 0);
        margin: auto;

        a {
          color: rgb(0, 0, 0);
          margin: auto;
         // font-weight: bold;
          font-size: 12px;
          
        }
      }
    }
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        .ant-menu-submenu-arrow {
          color: rgb(0, 0, 0);
        }
      }
      color: black;
      background:  #fccb81;
      
      .ant-menu {
        background:  #ffe6a0;
        
        color: black;
      .ant-menu-item {
        background:  #ffe6a0;
        margin: auto;
        color: rgb(0, 0, 0);
       
        a {
          color: rgb(21, 21, 21);
          
         
         
        }
      }
      }
    }
  }
  .ant-layout-sider-trigger {
    background:  #fccb81;
  
  }
}

.logo2{
  //height:100px;

  background-color: white;
 
  //animation: roll 0s infinite;
 
  

  
}
.sidebar-format{

  
  margin-top: 70px;

  //z-index: 99;
  border-radius: 10px 10px 0px 0px;
 
  


}
.logo{
  
  
  margin-inline-start: -190px;
  margin-top: 1px;
 

  animation: roll 30s infinite;
 
  
  width:100px;
   height:80px;
}

.header-format{

  
  //background-image:url("/images/webheader.png");
  background:  #fccb81;
  
  border-radius: 10px; /*rounded corners*/

 margin-inline-start: 195px; 
 
  
 

  top: 0%;
   z-index: 90;
  width:100%;
  position:fixed;
 
    
   
  
}

.header-format1{

  
  
  background-color: transparent;
 
  border-color: transparent;
  margin-inline-end: 200px;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.tag-balance-format{

  
  background-color:  #f0f0f0;
 
  border-radius: 20px; 
  font-weight: bold;
  font-size: medium;



  
}

@keyframes roll {
  0% {
    transform: rotateY(100);
  }
  100% {
    transform: rotateY(360deg);
  }

}
