.content-header {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 25px;
}
.content-header1 {
  
 
  position:relative;  
  z-index: 2;
  background: rgb(255, 203, 135);
  opacity: .90;
  border-radius: 1px;
  box-shadow: 0 10px 25px rgba(214, 149, 8, 0.2);
 
   text-align: center;
  
   /* display:flow-root; 
     overflow: hidden;
    width: 100%;
      border-image-slice: 0;
  border-image-source: linear-gradient(to right, #FF5733, #FF5733);
  padding: 1%;
margin: 30%;
  /*border: 3px solid;*/
 font-weight: bold;
 
  border-radius: 0px 0px;
  
 
}
.header-background {
  
  background-image:url ("/images/appbackground.png");
 
}