
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.spine {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}
.inputText {
  width: 50%;
  border-radius: 4px;
  margin: 8px 0;
  box-sizing: border-box;
  padding-left: 3%;padding-right: 3%;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}
