@import '../../../../app/styles/style';

.ant-dropdown {
  z-index: 100;
}

.actionMenuClass{
  z-index: 9999;
}

.editFilled{
  color: #13c2c2;
  cursor: pointer;
}
.deleteFilled{
  color: red;
  cursor: pointer;
}
.cardClass{

  
  text-align: center;


}
.cardTextClass{

  
  text-align: center;
  background-color: rgb(255, 231, 199);
  
  font-weight: bold;
  font-size: 150%;
  color: blue;


}
