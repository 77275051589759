@import "../../common/styles/Auth";

.token-validate-loader {
  display: block;
  margin-top: 20px;
  text-align: center;
  p {
    margin-top: 20px;
    font-size: 14px;
  }
}