@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import "colors";

@font-face {font-family: "Nassim";
  src: url("//db.onlinewebfonts.com/t/29e9a3f5cf847428382058f505969b17.eot");
  src: url("//db.onlinewebfonts.com/t/29e9a3f5cf847428382058f505969b17.eot?#iefix") format("embedded-opentype"),
  url("//db.onlinewebfonts.com/t/29e9a3f5cf847428382058f505969b17.woff2") format("woff2"),
  url("//db.onlinewebfonts.com/t/29e9a3f5cf847428382058f505969b17.woff") format("woff"),
  url("//db.onlinewebfonts.com/t/29e9a3f5cf847428382058f505969b17.ttf") format("truetype"),
  url("//db.onlinewebfonts.com/t/29e9a3f5cf847428382058f505969b17.svg#Nassim") format("svg"); }

[dir=ltr] body {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  color: $foreground;
}
[dir=rtl] body {
  font-family: "Nassim", "Helvetica Neue", sans-serif;
  color: $foreground;
}
html {
  font-size: 100%;
}
html, body {
  height: 100%;
}
.popup-ct {
  position: relative;
}
svg {
  flex-shrink: 0;
}
.nowrap {
  white-space: nowrap;
}
/*a {
  border-bottom: 1px solid;
  padding-bottom: 3px;

  &:hover {
    border-bottom: 0;
  }
}*/
#app {
  height: 100%;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.red{
  color: red;
}
.green {
  color :green;
}

.phone-number {
  direction: ltr;
}

@media screen and (min-width: 320px) { body {font-size:0.5rem;} }
@media screen and (min-width: 640px) { body {font-size:0.9rem;} }
@media screen and (min-width:960px) { body {font-size:1.1rem;} }
@media screen and (min-width:1100px) { body {font-size:1.3rem;} }
