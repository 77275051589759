@import "../../common/styles/Auth";

.back-to-login {
  color: $woodland;
  margin-top: 10px;
  &:hover{
    color: $woodland;
  }
  
}
