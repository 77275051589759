@import "colors";

.ant-form-item-explain {
  position: relative;
}
.ant-form-item-explain div {
  text-align: right;
  font-size: 13px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.text-input {
  height: 36px;
  border-radius: 10px;
  border-color: silver;
  
  
  
}

.ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input-affix-wrapper:hover, .ant-form-item-has-error .ant-input:hover {
  border-color: $red;
}

.response-error-msg {
  border: 2px dotted darken($red, 5%);
  color: $woodland;
  padding: 10px 10px 10px 14px;
  margin: 30px 0 15px;
}